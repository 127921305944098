const ContactForm = () => {
    const contactForm = document.querySelector("#contact-form");
    if (contactForm) {
        const btnSubmit = contactForm.querySelector('[type="submit"]');
        // const divResponse = contactForm.querySelector('.nl-response');

        contactForm.addEventListener("submit", e => {
            e.preventDefault();
            e.stopPropagation();

            btnSubmit.setAttribute('disabled', 'disabled');
            if (contactForm.checkValidity()) {

                grecaptcha.ready(function () {
                    grecaptcha.execute('6LfqenYhAAAAALLiwHoX2ql0Q-3V35kYyWxhe_HH', {action: 'submit'}).then(function (token) {
                        fetch('/ajax-contact-form.php', {
                            method: 'post',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                recaptcha_response: token,
                                contactEmail: contactForm.querySelector('[name="contactEmail"]').value,
                                contactName: contactForm.querySelector('[name="contactName"]').value,
                                contactMessage: contactForm.querySelector('[name="contactMessage"]').value
                            })
                        })
                            .then((response) => {
                                setTimeout(() => {
                                    btnSubmit.removeAttribute('disabled');
                                    // divResponse.innerHTML = '';
                                }, 5000);
                                if (response.ok) {
                                    return response.json();
                                } else {
                                    throw new Error('Une erreur est survenue, inscription impossible');
                                }
                            })
                            .then((response) => {
                                const toast = contactForm.querySelector('.toast');
                                const Toast = new bootstrap.Toast(toast);
                                Toast.show();
                                // document.querySelector('body').append(
                                //     '<div class="toast align-items-center" role="alert" aria-live="assertive" aria-atomic="true">\n' +
                                //     '  <div class="d-flex">\n' +
                                //     '    <div class="toast-body">\n' +
                                //     '      Hello, world! This is a toast message.\n' +
                                //     '    </div>\n' +
                                //     '    <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>\n' +
                                //     '  </div>\n' +
                                //     '</div>')
                                // divResponse.classList.remove('text-danger');
                                // divResponse.classList.add('text-success');
                                // divResponse.innerHTML = 'Inscription réussie';
                            })
                            .catch((error) => {
                                // divResponse.classList.remove('text-success');
                                // divResponse.classList.add('text-danger');
                                // divResponse.innerHTML = error;
                            });

                    });
                });
            }
        });
    }
}