const EatingMenu = () => {
    if (typeof EasyMDE !== 'undefined') {
        new EasyMDE({
            autofocus: true,
            autosave: {
                enabled: true,
                uniqueId: "zev5zev57ezv",
                delay: 1000,
                submit_delay: 5000,
                timeFormat: {
                    locale: 'fr-FR',
                    format: {
                        year: 'numeric',
                        month: 'long',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                    },
                },
                text: "Autosaved: "
            },
            toolbar: ["bold", "italic", "strikethrough", "|", "heading-1", "heading-2", "heading-3", "unordered-list", "quote", "table", "|", "undo", "redo", "|", "preview", "side-by-side", "fullscreen", "|", "guide"]
        });
    }

    const menuContainer = document.getElementById('eat-menu-container');
    if (menuContainer) {
        fetch('/menu.html', {
            method: 'get',
        })
            .then((response) => {
                if (response.ok) {
                    return response.text();
                } else {
                    throw new Error('Une erreur est survenue, impossible de récupérer le menu');
                }
            })
            .then((response) => {
                menuContainer.innerHTML = response;
            });
    }
}